(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-outcomes-to-srow.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-outcomes-to-srow.js');
"use strict";


const {
  SYSTEMS,
  getSystemKeyFromNumber
} = svs.components.sport.systems;
const getUsignSelections = (uSign, row) => [uSign].concat(row.filter(outcome => outcome !== uSign));
const produceSystemRows = (matrix, systemNum, uSigns) => {
  const systemRows = SYSTEMS[getSystemKeyFromNumber(systemNum)].rows;
  const [singlesIndices, halfHedgesIndices, fullHedgesIndices] = matrix.reduce((indices, row, index) => {
    var _indices;
    const hedges = row.length;
    const bucket = indices[hedges - 1] = (_indices = indices[hedges - 1]) !== null && _indices !== void 0 ? _indices : [];
    bucket.push(index);
    return indices;
  }, [[], [], []]);
  const srows = [];
  for (const systemRow of systemRows) {
    let currentFullIndex = -1;
    let currentHalfIndex = fullHedgesIndices.length - 1;
    const srow = [];
    for (const rowNum of fullHedgesIndices) {
      const next = systemRow[++currentFullIndex];
      if (uSigns) {
        const uSign = uSigns[rowNum];
        const uSignsSelections = getUsignSelections(uSign, matrix[rowNum]);
        srow[rowNum] = [uSignsSelections[next]];
        continue;
      }
      srow[rowNum] = [next];
    }
    for (const rowNum of halfHedgesIndices) {
      const next = systemRow[++currentHalfIndex];
      if (uSigns) {
        const uSign = uSigns[rowNum];
        const uSignsSelections = getUsignSelections(uSign, matrix[rowNum]);
        srow[rowNum] = [uSignsSelections[next]];
        continue;
      }
      const outcomes = matrix[rowNum];
      srow[rowNum] = [outcomes[next]];
    }

    for (const rowNum of singlesIndices) {
      srow[rowNum] = [matrix[rowNum][0]];
    }
    srows.push(srow);
  }
  return srows;
};

const getCartesianProducts = function () {
  let combinations = [[]];
  for (var _len = arguments.length, inputArrays = new Array(_len), _key = 0; _key < _len; _key++) {
    inputArrays[_key] = arguments[_key];
  }
  for (const input of inputArrays) {
    const newCombinations = [];
    for (const combination of combinations) {
      for (const item of input) {
        const newCombination = [...combination, item];
        newCombinations.push(newCombination);
      }
    }
    combinations = newCombinations;
  }
  return combinations;
};

const expandOutcomesToSROW = function () {
  for (var _len2 = arguments.length, outcomes = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    outcomes[_key2] = arguments[_key2];
  }
  return getCartesianProducts(...outcomes.map(outcome => getCartesianProducts(...outcome)));
};

const expandSystemOutcomesToSROW = function (outcomes) {
  let mSigns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let uSigns = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  let systemNum = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  const systemRows = SYSTEMS[getSystemKeyFromNumber(systemNum)];
  const uRows = uSigns !== null && uSigns !== void 0 && uSigns.length ? uSigns : null;
  if (!systemRows) {
    throw new Error("Unsupported system: ".concat(systemNum));
  }
  const rowsWithMSigns = outcomes.filter((_, index) => mSigns[index]);
  if (rowsWithMSigns.length === 0) {
    return produceSystemRows(outcomes.flat(), systemNum, uRows);
  }

  const mSignRowsIndices = mSigns.map((sign, i) => sign ? i : -1).filter(i => i > -1);

  const rowsWithMSignsAsSrow = expandOutcomesToSROW(...rowsWithMSigns);
  return rowsWithMSignsAsSrow.map(srow => {
    const systemSigns = JSON.parse(JSON.stringify(outcomes.flat()));
    for (const [outcomeIndex, rowIndex] of Object.entries(mSignRowsIndices)) {
      systemSigns[rowIndex] = srow[outcomeIndex];
    }
    return systemSigns;
  })
  .flatMap(systemSigns => produceSystemRows(systemSigns, systemNum, uRows));
};
const filterFn = (h, a) => h - a;
const homeFilter = (h, a) => filterFn(h, a) > 0;
const drawFilter = (h, a) => filterFn(h, a) === 0;
const awayFilter = (h, a) => filterFn(h, a) < 0;
const scoreFilterFnMap = {
  0: homeFilter,
  1: drawFilter,
  2: awayFilter
};
const REDUCTIONS_BOMBEN = [0, 1, 2];
const expandScoreOutcomesRsysToSrows = (outcomes, reductions) => {
  const sRowsFromOutcomes = expandOutcomesToSROW(...outcomes);
  const sRowsWithOutReductions = [];
  for (const row of sRowsFromOutcomes) {
    let isAnyOfEventOutcomeReduced = false;
    for (const [index, [home, away]] of Object.entries(row)) {
      const reductionValues = REDUCTIONS_BOMBEN.filter(reductionValue => !reductions[index].includes(reductionValue));
      const reductionFnsForEvent = reductionValues.map(reduction => scoreFilterFnMap[reduction]);
      for (const reductionsFunc of reductionFnsForEvent) {
        if (reductionsFunc(home, away)) {
          isAnyOfEventOutcomeReduced = true;
          break;
        }
      }
      if (isAnyOfEventOutcomeReduced) {
        break;
      }
    }
    if (!isAnyOfEventOutcomeReduced) {
      sRowsWithOutReductions.push(row);
    }
  }
  return sRowsWithOutReductions;
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandOutcomesToSROW,
  expandSystemOutcomesToSROW,
  getCartesianProducts,
  expandScoreOutcomesRsysToSrows
});

 })(window);