(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/get-outcome-distribution-matrix.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/get-outcome-distribution-matrix.js');
"use strict";

const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  utils
} = svs.components.tipsen.expandedSystems;
const calculateDistributions = distributionsMatrix => distributionsMatrix.map(row => {
  const sum = row.reduce((sum, col) => sum + col, 0);
  return row.map(col => parseFloat((col / sum).toPrecision(2)));
});

const getOutcomeDistributionMatrix = function (srows) {
  let {
    eventTypeId = EventTypeId.EVENT_1X2
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const outcomeCount = utils.getOutcomeCountForEvent(eventTypeId);
  const distributionsMatrix = [];
  for (const srow of srows) {
    for (let rowNum = 0; rowNum < srow.length; rowNum++) {
      const colNum = srow[rowNum];
      distributionsMatrix[rowNum] = distributionsMatrix[rowNum] || Array(outcomeCount).fill(0);
      distributionsMatrix[rowNum][colNum]++;
    }
  }
  return calculateDistributions(distributionsMatrix);
};
setGlobal('svs.components.tipsen.expandedSystems', {
  getOutcomeDistributionMatrix
});

 })(window);