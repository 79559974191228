(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-scoreboard-to-signs.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-scoreboard-to-signs.js');
"use strict";


const {
  utils
} = svs.components.tipsen.expandedSystems;
const scoreReductions = {
  HOME: 0,
  DRAW: 1,
  AWAY: 2
};

const expandScoreboardToSign = outcomes => {
  const signs = [];
  let participantSigns;
  outcomes.boardData.forEach((board, index) => {
    if (index % 2 === 0) {
      participantSigns = [];
    }
    const allValuesForParticipant = [];
    board.forEach(value => {
      allValuesForParticipant.push(utils.convertScoreboardDataToSign(value));
    });
    participantSigns.push(allValuesForParticipant);
    if (index % 2 !== 0) {
      signs.push(participantSigns);
    }
  });
  let reduction = [];
  if (outcomes.rSysData) {
    reduction = outcomes.rSysData.map(eventReductions => eventReductions.map(reduction => scoreReductions[reduction]));
  }
  return [signs, reduction];
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandScoreboardToSign
});

 })(window);