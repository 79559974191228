(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-board-to-matrix.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-board-to-matrix.js');
"use strict";

const {
  rowToIndexes
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  utils
} = svs.components.tipsen.expandedSystems;

const expandMSYSToOutcomeMatrix = (board, eventTypeId) => {
  const outcomeCount = utils.getOutcomeCountForEvent(eventTypeId);
  if (!outcomeCount) {
    throw new Error("Unknown eventTypeId: ".concat(eventTypeId));
  }
  if (eventTypeId === EventTypeId.GOAL_COUNT) {
    return [board.map(row => rowToIndexes(row, outcomeCount))];
  }
  const mSigns = [];
  const signs = [];
  board.forEach(row => {
    mSigns.push(row.includes('M'));
    signs.push(rowToIndexes(row.replace('M', '').split('').map(n => utils.outcomeMap[n]), outcomeCount));
  });
  return [signs, mSigns];
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandMSYSToOutcomeMatrix
});

 })(window);