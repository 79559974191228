(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-outcome-pairs-to-signs.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-outcome-pairs-to-signs.js');
"use strict";


const {
  expandScoreboardToSign,
  utils
} = svs.components.tipsen.expandedSystems;

const expandOutcomePairsToSigns = outcomePairsForAllEvents => {
  const outcomes = {
    boardData: utils.convertOutcomePairsToBoardData(outcomePairsForAllEvents)
  };
  const [signs, reductions] = expandScoreboardToSign(outcomes);
  return [signs, reductions];
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandOutcomePairsToSigns
});

 })(window);