(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-index-signs-to-matrix.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-index-signs-to-matrix.js');
"use strict";


const {
  utils
} = svs.components.tipsen.expandedSystems;
const {
  rowToIndexes
} = svs.components.sport.tipsenShared;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;


const expandIndexSignsToMatrix = function (signs) {
  let eventTypeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EventTypeId.EVENT_1X2;
  const outcomeCount = utils.getOutcomeCountForEvent(eventTypeId);
  if (!outcomeCount) {
    throw new Error("Unknown eventTypeId: ".concat(eventTypeId));
  }
  const convertedSigns = [];
  signs.forEach(sign => {
    const home = sign[0];
    convertedSigns.push(rowToIndexes(home, outcomeCount));
  });
  return convertedSigns;
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandIndexSignsToMatrix
});

 })(window);