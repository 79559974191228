(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-scoreboard-to-srow.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-scoreboard-to-srow.js');
"use strict";


const {
  utils,
  getCartesianProducts
} = svs.components.tipsen.expandedSystems;

const expandOutcomePairsToSrow = outcomes => {
  const sRows = getCartesianProducts(...outcomes);
  return sRows;
};

const expandScoreboardOutcomePairsToSrowHex = (outcomes, eventTypeId) => {
  const sRows = expandOutcomePairsToSrow(outcomes);
  const hexRows = utils.compressSROWToHex(sRows, {
    eventTypeId
  });
  return hexRows;
};
const expandERScoreboardToSrow = erBoards => {
  const sRows = erBoards.map(board => {
    let matchOutcomes = [];
    const eventOutcomes = [];
    board.split('').forEach((outcome, index) => {
      matchOutcomes.push(outcome);
      if (index % 2 !== 0) {
        eventOutcomes.push(matchOutcomes);
        matchOutcomes = [];
      }
    });
    return eventOutcomes;
  });
  return sRows;
};

const expandERScoreboardToSrowHex = (erBoards, eventTypeId) => {
  const sRows = expandERScoreboardToSrow(erBoards);
  const hexRows = utils.compressSROWToHex(sRows, {
    eventTypeId
  });
  return hexRows;
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandScoreboardOutcomePairsToSrowHex,
  expandERScoreboardToSrowHex
});

 })(window);