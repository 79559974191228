(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/expanded-systems/assets/expand-er-board-to-indexes.js') >= 0) return;  svs.modules.push('/components/tipsen/expanded-systems/assets/expand-er-board-to-indexes.js');
"use strict";

const {
  utils
} = svs.components.tipsen.expandedSystems;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;

const expandErSROWHexBoardToOutcomeIndexes = board => board.toUpperCase().match(utils.compileRegex(utils.getHexadecimalLengthByBoard(board))).map(n => [parseInt(n, 16).toString(2).split('').reverse().indexOf('1')]);

const expandErHexBoardToOutcomeIndexes = function (board) {
  let {
    eventTypeId = EventTypeId.EVENT_1X2
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const outcomeCount = utils.getOutcomeCountForEvent(eventTypeId);
  const outcomes = board.toUpperCase().match(utils.compileRegex(utils.getHexadecimalLengthByBoard(board))).map(outcome => [parseInt(outcome, 16).toString(2).split('').reverse().map((outcome, index) => {
    const isSignInIndex = Boolean(Number(outcome));
    return isSignInIndex ? index : -1;
  }).filter(outcome => outcome !== -1).slice(0, outcomeCount)]);
  return outcomes;
};
setGlobal('svs.components.tipsen.expandedSystems', {
  expandErSROWHexBoardToOutcomeIndexes,
  expandErHexBoardToOutcomeIndexes
});

 })(window);